/* Panel.css */
.panel {
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

#veri {
  margin-bottom: 10px;
}

.panel h2 {
  text-align: center;
  color: #333;
}

.panel .form-group {
  margin-bottom: 15px;
}

.panel label {
  display: block;
  color: #555;
  margin-bottom: 5px;
}

.panel input {
  width: 100%;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.panel button {
  width: 100%;
  padding: 14px;
  border-radius: 5px;
  border: none;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.panel #sil {
  margin-top: 10px;
  background-color: red;
}

.panel button:hover {
  background-color: #218838;
}

.panel .error {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.panel .success {
  color: green;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Mobil Uyum */
@media screen and (max-width: 600px) {
  .panel {
      width: 40vw;
  
  .panel h2 {
      font-size: 20px;
  }

  .panel input,
  .panel button {
      padding: 10px;
      font-size: 14px;
  }
}
}