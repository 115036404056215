/* UserList.css */
.user-list {
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.user-list h2 {
  text-align: center;
  color: #333;
}

.user-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto; /* Yatay kaydırma çubuğunu tetiklemek için */
}

.user-list th, 
.user-list td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.user-list th {
  background-color: #f4f4f4;
  color: #333;
}

.user-list .error {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Mobil Uyum */
@media screen and (max-width: 600px) {
  .user-list {
    width: 90%; /* Genişliği yüzdeye ayarlayarak */
    margin: 20px auto;
    padding: 10px;
  }

  .user-list h2 {
    font-size: 20px;
  }

  .user-list th,
  .user-list td {
    padding: 8px;
    font-size: 14px;
  }
}
