/* Styles/Lefalet.css */

/* Mevcut stil dosyasınıza mobil uyumluluk ekleyin */

/* Genel stil */
body {
  font-family: Arial, sans-serif;
}

/* Harita ve çevresindeki stil */
#mapid {
  width: 100%;
  height: 500px;
  margin: auto;
}
.pnlbtn{
  background: none;
  background-color: none;
  border: none;
  height: 5vh;
 
  width: 100px;
}


.map-container {
  margin-top: -80px; /* Burayı değiştirerek daha aşağıda görünmesini sağladık */
  background-color: rgba(162, 155, 148, 0.7);
  border-left: 0.5px dashed #A29B94;
  border-top: 0.5px dashed #A29B94;
  border-bottom: 0.5px dashed #A29B94;
  border-radius: 8px;
 
  padding: 10px;
  width: 60vw;
  margin-bottom: 20px;
  z-index: 1;
}

.map-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  text-align: center;
  background-color: rgba(162, 155, 148, 0.8);
  color: rgb(243, 249, 235);
  font-size: 18px;
  margin-top: 2vh;
}

/* Mobil cihazlar için medya sorgusu */
@media (max-width: 768px) {
  .map-container {
    margin-top: 20px; /* Mobil cihazlarda biraz daha yukarıda */
    padding: 5px;
  }

  .map-header {
    font-size: 16px;
    height: 5vh;
  }

  #mapid {
    width: 50%;
    height: 300px;
  }
}

/* Daha küçük mobil cihazlar için medya sorgusu */
@media (max-width: 480px) {
  .map-container {
    margin-top: 100px; /* Daha küçük cihazlarda biraz daha yukarıda */
    padding: 5px;
    width: 50vw;
  }

  .map-header {
    font-size: 14px;
    height: 4vh;
  }

  #mapid {
    width: 100%;
    height: 250px;
  }
}
