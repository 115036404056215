* {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
}

.login {
    height: 100vh;
    background: url('../img/background-main.jpg') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: center;

}


.login-title p{
    margin-bottom: 3px;
}

.login-title {
   background-color: rgb(12, 12, 12,0.7);
   width: 84vw;
   height: 10vh;
   font-weight: 500;
   margin-top: 10vh;
   color: #C0CE9B;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}


/*input box tamamen burada BAŞLAR*/

.loginbox {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(75, 76, 71, 0.4);
    height: 60vh;
    width: 80vw;
    
    padding: 30px;
  
 
}

.log-box-input{
 
    margin-top: 5vh;
    background: url(../img/background-main.jpg);
    width: 40vw;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 2px;
}

.log-box-input .input-tc{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40%;
    align-items: center;
   


}


.log-box-input .input-tc label{
  margin-left: 10px;


}

.log-box-input .input-tc input{
   width: 50%;
   height: 5vh;
   background-color: rgb(245, 234, 222);
   border: none;
   border-radius: 4px;
   margin-right: 20px;

}

.log-box-input .sec-code{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 10vh;
    margin-right: 13vw;
  
    

}
.log-box-input h5{
  
        display: flex;
        justify-content: center;
        height: 6vh;
        align-items: center;
        background: url("../img/sec-code-back.png");
        width: 15vw;
        font-size: 36px;
        letter-spacing: 2px;
        font-style: italic; /* Italik yapma */
        color: #EDBA9F;
        text-align: center;
       
       
        filter: contrast(90%) brightness(95%); /* Hafif eskitilmiş görünüm için filtreler */
    
    
  
    

}

.log-box-input .input-code{
   
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40%;
    align-items: center;
   
}

.log-box-input .input-code input{
    width: 50%;
    height: 5vh;
    background-color: rgb(245, 234, 222);
    border: none;
    border-radius: 4px;
    margin-right: 20px;
 
 }
 .log-box-input .input-code label{
    margin-left: 10px;
  
  
  }
  






















/*input box tamamen burada BİTER*/




/*QUERY BUTTON */

.login-btn {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 8vh;
    
}

.query-btn {
  margin-right: 20px;
  background-color: #6e6257;
  border: none;
  height: 4vh;
  width: 6vw;
  border-radius: 2px;
  color: white;
  font-size:medium;

 
}

.query-btn:hover {
    background-color: #ce9e72;
}






.error {
    color: red;
    margin-top: 1vh;
    font-size: 1.2em;
}



/* Normal stiller */

/* Bu kısım ekran boyutu 768 pikselden büyük olduğunda geçerli olacak stilleri içerir */
@media only screen and (min-width: 768px) {

    

  
    .login-title {
        width: 60vw;
       
    }

    .loginbox {
        width: 60vw;
    }

    .log-box-input {
        width: 50vw;
    }

    .log-box-input .input-tc input,
    .log-box-input .input-code input {
        width: 40%;
    }
}

/* Bu kısım ekran boyutu 576 pikselden büyük ancak 768 pikselden küçük olduğunda geçerli olacak stilleri içerir */
@media only screen and (max-width: 767px) {
    /* QUERY BUTTON */


    .log-box-input .sec-code h5{
        font-size: 18px;
      
        
    
    }

.login-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 8vh;
}

.query-btn {
    background-color: #6e6257;
    border: none;
    height: 4vh;
    width: 20vw; /* Değişiklik: Sabit piksel yerine yüzde bazlı genişlik */
    border-radius: 2px;
    color: white;
    font-size: medium;
    margin-right: 5%; /* Değişiklik: Sağdan boşluk vermek için yüzde bazlı margin */
    text-align: center; /* Yeni eklendi: Buton metnini ortalamak için */
}

.query-btn:hover {
    background-color: #ce9e72;
}

.login-title {
    padding: 10px;
    width: 70vw;
    font-size: 14px;
}

    .loginbox {
        width: 70vw;
    }

    .log-box-input {
        width: 70vw;
    }

    .log-box-input .input-tc input,
    .log-box-input .input-code input {
        width: 50%;
    }
}

/* Bu kısım ekran boyutu 576 pikselden küçük olduğunda geçerli olacak stilleri içerir */
@media only screen and (max-width: 575px) {
    .login-title p {
        width: 70vw;
        font-size: 14px;
    }

    .loginbox {
        width: 80vw;
    }

    .log-box-input {
        width: 80vw;
    }

    .log-box-input .input-tc input,
    .log-box-input .input-code input {
        width: 60%;
    }
}
