* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

.main {
  height: 100vh;
  width: 100%; /* Genişlik %100 */
  max-width: 12100px; /* Maksimum genişlik 1200 piksel */
  background: url("../img/background-main.jpg") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: -1;
}

.main-buttons {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-buttons #button {
  z-index: 1;
  width: 315px;
  height: 25px;
  border: none;
  background-color: #2d1d0c;
  color: white;
  margin-bottom: 6px;
}

.main-buttons button a {
  color: white;
}

.main-fotos {
  position: absolute;
  top: 450px;
  z-index: 0;
}

.main-footer {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  color: rgba(229, 238, 211, 0.6);
  background-color: rgba(0, 0, 0, 0.7);
  height: 10vh;
  width: 100vw;
}


/* Tablet ve altı cihazlar için stil */
@media (max-width: 768px) {
  .main {
    padding: 20px;
    justify-content: flex-start;
  }

  .main-buttons button {
    width: 80%;
    height: 40px;
    font-size: 16px;
  }

  .main-fotos {
    top: auto;
    bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .main-footer {
    height: 15vh;
    font-size: 14px;
  }
  .main-footer p{
    font-size: 8px;
    padding-bottom: 10px;
  }
}

/* Mobil cihazlar için stil */
@media (max-width: 480px) {
  .main {
    display: flex;
   
    align-items: center;
    flex-direction: column;
    height: 100vh; /* Eklendi */
    padding: 0; /* Eklendi */
  }

  .main-buttons button {
    width: 90%;
    height: 50px;
    font-size: 18px;
  }

  .main-fotos {
   height: 40vh;
   z-index: 0;
  }

  .main-footer {
    
  
    left: auto; /* Sol konumu sıfırla */
    height: 20vh;
    width: 100%; /* Genişlik 100% olarak ayarlandı */
   z-index: 1;
   
    display: flex;
   
    background-color: rgba(0, 0, 0, 0.7);
    color: rgba(229, 238, 211, 0.6);
  }

  .main-footer p {
   
    font-size: 12px;
  }
}
