* {
  padding: 0;
  margin: 0;
}

.result {
  height: 100vh;
  background: url("../img/background-main.jpg") no-repeat center center;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.result-see {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(104, 100, 100, 0.7); 
  height: 50vh;
  width: 60vw;
}

.result-intro {
  background-color: rgba(12, 12, 12, 0.7);
  width: 100%;
  height: 10vh;
  font-weight: 500;
  margin-top: 10vh;
  color: #c0ce9b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.result-content {
  margin-top: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-item-pair {
  padding-right: 40px;
  background-color: #F29E20;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  background-color: #F29E20;
  color: white;
}

.item {
  background-color: #F3F3F3;
  width: 156%;
}

.result-idk {
  margin-top: 6px;
  width: 53vw;
  height: 4vh;
  border-radius: 2px;
  background: url("../img/background-main.jpg") no-repeat center center;
  background-size: cover;
}

/* Mobil uyumlu versiyon */
@media only screen and (max-width: 768px) {
  .result {
    height: auto;
    padding: 20px;
  }

  .result-see {
    height: auto;
    width: 90vw;
    padding: 20px;
  }

  .result-intro {
    height: auto;
    margin-top: 5vh;
    padding: 10px;
    text-align: center;
  }

  .result-content {
    margin-top: 5vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
  }

  .title-item-pair {
    padding-right: 20px;
  }

  .title {
    height: 5vh;
    padding: 10px;
  }

  .item {
    height: 4vh;
    width: 100%;
    padding: 10px;
  }

  .result-idk {
    width: 90vw;
    height: auto;
  }
}
