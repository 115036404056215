/* Center the form on the page */
.panel-control-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

/* Style the form */
.panel-control-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px; /* Changed width to max-width */
  width: 100%; /* Added width */
  text-align: center;
}

/* Style the label */
.panel-control-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

/* Style the input field */
.panel-control-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

/* Style the submit button */
.panel-control-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.panel-control-form button:hover {
  background-color: #0056b3;
}

/* Style the panel */
.panel {
  text-align: center;
  padding: 20px;
}

/* Responsive design for screens narrower than 768px */
@media screen and (max-width: 768px) {
  .panel-control-form {
    height: 20vh;
      width: 50vw; /* Set to full width */
  }
}
